<template>
  <div class="trips-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Trips</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 me-4 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search trip.."
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn v-if="profile.role != 'trip_organizer'" to="/trip-new" depressed width="136" large class="primary mt-sm-0 mt-2">
          Add new
        </v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.type>
            Type
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="type != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="type == item.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsTypes"
                  :key="item.id"
                  @click="(type = item.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.name_en }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:header.destination_country>
            Destination
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="city != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="city == item.city.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsDes"
                  :key="item.country.id"
                  @click="(city = item.city.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.country.name }} {{ item.city.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:header.is_active>
            Active
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="active != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="active == item.id ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsActive"
                  :key="item.id"
                  @click="(active = item.id), getData()"
                  style="min-height: 36px"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openTrip(item.id)" style="height: 64px">
                <td class="text-capitalize">{{ item.title_en }}</td>
                <td class="text-capitalize">{{ item.type.name_en }}<br />{{ item.subtype }}</td>
                <td>
                  {{ item.departure ? `${item.departure.country.name} ${item.departure.city.name}` : '' }}
                </td>
                <td>
                  {{
                    item.destinations.length
                      ? `${item.destinations[0].destination.country.name}, ${item.destinations[0].destination.city.name}`
                      : ''
                  }}
                  <br />
                  <span v-if="item.destinations.length > 1">+ {{ `${item.destinations.length - 1} more destinations` }}</span>
                </td>
                <td>
                  From: {{ new Date(item.available_since).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                  <br />
                  Till: {{ new Date(item.available_till).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                </td>
                <td>
                  <v-icon color="primary">{{ item.special_offer ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
                <td>
                  <v-icon color="primary">{{ item.is_active ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
                <td>
                  <v-icon color="primary">{{ item.featured ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
                <td>
                  <v-icon color="primary">{{ item.is_hidden ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'trips' : 'trip' }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openTrip(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Trip</div>
              <div class="font-weight-medium f18">{{ item.title_en }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Type</div>
              <div class="font-weight-medium f18 text-truncate">{{ item.type.name_en }}<br />{{ item.subtype }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Departure</div>
              <div class="font-weight-medium f18 text-capitalize">
                {{ item.departure ? `${item.departure.country.name} ${item.departure.city.name}` : '' }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Destination</div>
              <div class="font-weight-medium f18 text-capitalize">
                {{
                  item.destinations.length
                    ? `${item.destinations[0].destination.country.name}, ${item.destinations[0].destination.city.name}`
                    : ''
                }}
                <br />
                <span v-if="item.destinations.length > 1">+ {{ `${item.destinations.length - 1} more destinations` }}</span>
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Available</div>
              <div class="font-weight-medium f18 text-capitalize">
                From: {{ new Date(item.available_since).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
                <br />
                Till: {{ new Date(item.available_till).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Special offer</div>
              <v-icon color="primary">{{ item.special_offer ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Active</div>
              <v-icon color="primary">{{ item.is_active ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Featured</div>
              <v-icon color="primary">{{ item.featured ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Hidden</div>
              <v-icon color="primary">{{ item.is_hidden ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} {{ data.total > 1 ? 'trips' : 'trip' }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      tripGroup: this.$route.query.tgr || '',
      search: this.$route.query.src || '',
      type: this.$route.query.type || 'all',
      country: this.$route.query.dst_cnt || 'all',
      city: this.$route.query.dst_cit || 'all',
      active: this.$route.query.act || 'all',
      page: Number(this.$route.query.page) || 1,
      headers: [
        { text: 'Trip', value: 'title_en', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Departure', value: 'departure_country', sortable: false },
        { text: 'Destination', value: 'destination_country', sortable: false },
        { text: 'Available', value: 'available_since', sortable: false },
        { text: 'Special offer', value: 'special_offer', sortable: false },
        { text: 'Active', value: 'is_active', sortable: false },
        { text: 'Featured', value: 'featured', sortable: false },
        { text: 'Hidden', value: 'is_hidden', sortable: false },
      ],
      itemsActive: [
        { id: 'all', title: 'All' },
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getTripTypes').finally(() => {
      this.itemsTypes.unshift({ id: 'all', name_en: 'All Types', name_ar: 'All Types' });
    });
    this.$store.dispatch('getTripDes').finally(() => {
      this.itemsDes.unshift({ country: { id: '1', name: 'All' }, city: { id: 'all', name: 'locations' } });
    });
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openTrip(id) {
      this.$router.push(`/trips/${id}/general`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getTripsList', {
          tripGroup: this.tripGroup,
          search: this.search,
          type: this.type,
          country: this.country,
          city: this.city,
          active: this.active,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripsList;
    },
    itemsDes() {
      return this.$store.getters.tripDes;
    },
    itemsTypes() {
      return this.$store.getters.tripTypes;
    },
    profile() {
      return this.$store.getters.profile;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripsList');
  },
};
</script>

<style lang="scss">
.trips-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
